import Swiper from 'swiper';
import { Navigation, Autoplay } from 'swiper/modules';

export default function () {

    performance.mark("Swiper module starts")

    const cs = getComputedStyle(document.documentElement);

    //MAIN BANNER
    const mainBanner = new Swiper('.main-banner', {
        modules: [Navigation, Autoplay],
        preloadImages: false,
        autoplay: {
            delay: 7000
        },
        lazy: false,
        loop: true,
        navigation: {
            nextEl: '.swiper-button-next.main-banner-button',
            prevEl: '.swiper-button-prev.main-banner-button'
        },
        on: {
            beforeTransitionStart: function (instance) {

                const currentSlide = instance.slides[instance.activeIndex];
                const slideImg = currentSlide.querySelector('img');

                if (slideImg.hasAttribute('src')) return;

                currentSlide.classList.add('loaded');
                slideImg.setAttribute('src', slideImg.getAttribute('data-src'));
            }
        }
    });

    //MAIN PAGE PRODUCTS
    const breakpoints = {
        1: {},
        360: {},
        601: {},
        769: {},
        1280: {}
    };

    const promoSale = new Swiper('.slider-with-products.promo-sale .swiper', {
        modules: [Navigation],
        preloadImages: false,
        navigation: {
            nextEl: '.swiper-button-next.promo-sale-button',
            prevEl: '.swiper-button-prev.promo-sale-button'
        },
        loop: false,
        breakpoints: breakpoints,
        slidesPerView: Number(cs.getPropertyValue('--prodSwiperCols')),
        spaceBetween: Number(cs.getPropertyValue('--prodGapHor').replace('px', '')),
    });

    promoSale.on('breakpoint', function () {
        promoSale.params.slidesPerView = Number(cs.getPropertyValue('--prodSwiperCols'));
        promoSale.params.spaceBetween = Number(cs.getPropertyValue('--prodGapHor').replace('px', ''));
        promoSale.update();
    });



    performance.mark("Swiper module ends")

}